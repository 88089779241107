<template>
  <div class="relative">
    <closeIcon class="cursor-pointer absolute top-0 -right-4" @close="close"/>

    <p class="text-2xl text-black font-bold mb-16 pt-3 mb-6">Invite User</p>
    <form 
      @submit.prevent="addMember"
      class="flex flex-wrap items-center mb-7"
    >
      <label for="memberName" class="text-base text-black pb-5">Email address</label>
      <input 
        v-model="memberName"
        type="email"
        required
        id="memberName"
        placeholder="Enter an email address"
        class="w-full base-bg-darker-300 youTubeUrl-input rounded px-4 h-8 font-mono text-sm text-black outline-none placeholder-black"
      >

      <ButtonWithLoader 
        :disabled="loading"
        :disabledButton="disabledButton"
        btnText="Invite User"
        type="submit"
        bgClass="purple-bg"
        textClass="purple-light font-monoDemi text-sm"
        heigthClass="h-8"
        class="mt-6"
      />
    </form>

    <p v-if="inviteSent">Invite has been emailed to {{memberName}}. The invitation is valid for 72 hours. Alternatively you can also send the user this invitation link: {{inviteSent}}</p>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import closeIcon from '@/components/icons/close-icon'
  import ButtonWithLoader from '@/components/ButtonWithLoader'
  import { inviteUser } from '@/helpers/Mutations'
  // import { warningToastConfig } from '@/helpers/constants'
  // import ToastMessages from '@/data/toast_messages.json'

  export default {
    components: {
      closeIcon,
      ButtonWithLoader,
    },
    data(){
      return{
        memberName: '',
        loading: false,
        disabledButton: false,
        inviteSent: ''
      }
    },
    computed:{
      ...mapGetters({
        user_meta: 'general/user_meta',
        userAppData: 'general/userAppData'
      })
    },
    methods: {
      async addMember() {
        this.loading = true
        this.disabledButton = true
        // params first orgid, then emailaddress
        const result = await this.$mainClient.mutate(inviteUser({id: this.user_meta.organization.id, email: this.memberName}));
        this.inviteSent = result.data.createOrganizationInvite.reflink ? `https://tool.raditube.com/invite/${result.data.createOrganizationInvite.reflink}` : false; 

        this.loading = false
      },
      close(){
        this.$emit('close')
      }
    },
    mounted(){
      console.log(this);
      console.log(this.user_meta);
    }
  }
</script>

<style lang="scss" scoped>

</style>